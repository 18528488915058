import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { AppDispatch, RootState } from 'app/store';
import { ISubscription, IUpdateSubscriptionData } from './subscriptionTypes';
import { fetchSubscriptionByOrganozationId, changeSubscriptionInfo } from 'api/billing/subscriptions';
import { toast } from 'react-toastify';

export const getSubscriptionInfoByOrganozationId = createAsyncThunk<
  ISubscription,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@subscription/getSubscriptionInfo', async (id, thunkApi) => {
  try {
    const res =  await fetchSubscriptionByOrganozationId(id);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const updateSubscriptionInfo = createAsyncThunk<
  ISubscription,
  IUpdateSubscriptionData,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@subscription/updateSubscriptionInfo', async (data, thunkApi) => {
  try {
    const res = await changeSubscriptionInfo(data);
    toast.success('Subscription updated')
    return res.data
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
