import React, { useState, useEffect, FC } from "react";
import styles from './TermsOfUse.module.scss';
import CustomCheckbox from '../forms/CustomCheckbox';

interface TermsOfUseProps {
  termsUrl: string; 
  onChange: (accepted: boolean) => void;
}

const TermsOfUse: FC<TermsOfUseProps> = ({ termsUrl, onChange }) => {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAccepted(isChecked);
    onChange(isChecked);
  };

  return (
      <div className={styles.title}>
        <CustomCheckbox
          label={
            <>
              I agree to{' '}
              <a href={termsUrl} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </>
          }
          isChecked={accepted}
          handleChange={handleCheckboxChange}
        />
        {/*<input*/}
        {/*  type="checkbox"*/}
        {/*  checked={accepted}*/}
        {/*  onChange={handleCheckboxChange}*/}
        {/*  style={{ marginRight: "8px" }}*/}
        {/*/>*/}

        <span className={styles.message}>There are no refunds. Your subscription will renew automatically, and you will be charged in advance. You may cancel at any time. The cancellation goes into effect at the start of your following billing cycle.</span>
      </div>

  );
};

export default TermsOfUse;
