import React, { useEffect, useState } from 'react';
import styles from './Plans.module.scss';
import { useSelector } from 'react-redux';
import { pricesSelector } from 'features/prices/pricesSlice';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { IUpdateSubscriptionData } from 'features/subscription/subscriptionTypes';
import { updateSubscriptionInfo } from 'features/subscription/subscriptionActions';
import { useAppDispatch } from 'app/store';
import Button from 'components/shared/Button';
import { getCurrencySymbol } from 'helpers/getCurrencySymbol';
import TermsOfUse from 'components/shared/TermsOfUse';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import Modal from 'components/shared/Modal';
import { IPriceListItem } from 'features/prices/pricesTypes';
import { getUpcomingInvoiceBySubscriptionId } from 'features/upcomingInvoice/upcomingInvoiceActions';
import { upcomingInvoiceSelector } from 'features/upcomingInvoice/upcomingInvoiceSlice';

interface IProduct {
  id: string;
  name: string;
  description: string;
  featureList: string[];
  prices: IPriceListItem[];
}

const SubscriptionPlans: React.FC = () => {
  const dispatch = useAppDispatch();
  const prices = useSelector(pricesSelector);
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const upcomingInvoice = useSelector(upcomingInvoiceSelector);

  const [selectedPriceId, setSelectedPriceId] = useState(subscriptionInfo.priceId);
  const [selectedCurrency, setSelectedCurrency] = useState(subscriptionInfo.currency);
  const [selectedBillingPeriod, setBillingPeriod] = useState<'month' | 'year'>(subscriptionInfo.interval);
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);
  const [isPricingPlanModalOpen, setPricingPlanModalOpen] = useState(false);

  const currencyOptions = availableCurrencies.map(currency => ({label: currency.toUpperCase(), value: currency}));
  const billingPeriodOptions = [
    { label: "Annual", value: "year" },
    { label: "Monthly", value: "month" }
  ];

  useEffect(() => {
    const uniqueCurrencies = [
      ...new Set(prices.map((price) => price.currency)),
    ];
    setAvailableCurrencies(uniqueCurrencies);
    setSelectedCurrency(subscriptionInfo.currency);
  }, [prices]);

  useEffect(() => {
    if (subscriptionInfo?.id) {
      console.log(subscriptionInfo?.id)
      dispatch(getUpcomingInvoiceBySubscriptionId({subscriptionId: subscriptionInfo.id, priceId: selectedPriceId}))
    }
  }, [subscriptionInfo]);

  const filteredPrices = prices
    .filter((price) => price.currency === selectedCurrency)
    .sort((a, b) => a.productName.localeCompare(b.productName));

  const productPrices = filteredPrices.reduce((acc, price) => {
    if (!acc[price.productId]) {
      acc[price.productId] = {
        id: price.productId,
        name: price.productName,
        description: price.productDescription,
        featureList: price.productFeatureList,
        prices: [],
      };
    }
    acc[price.productId].prices.push(price);
    return acc;
  }, {} as Record<string, IProduct>);

  const handleSubmit = () => {
    const updateSubscriptionData: IUpdateSubscriptionData = {
      subscriptionId: subscriptionInfo.id,
      priceId: selectedPriceId ?? subscriptionInfo.priceId,
      quantity: subscriptionInfo.quantity,
    };

    dispatch(updateSubscriptionInfo(updateSubscriptionData));
    setPricingPlanModalOpen(false)
  };

  const handleUpdateButtonClick = (productId: string) => {
    const price = filteredPrices.find(
      (price) =>
        price.productId === productId &&
        price.interval === selectedBillingPeriod &&
        price.currency === subscriptionInfo.currency
    );
    setSelectedPriceId(price?.id ?? selectedPriceId)
    dispatch(getUpcomingInvoiceBySubscriptionId({subscriptionId: subscriptionInfo.id, priceId: price?.id})) 
    setPricingPlanModalOpen(true)
  };

  return (
    <div className={styles.wrapper}>
      <div style={{width: 250}}>
        <DropdownSelect
          label="Select Currency"
          value={currencyOptions.find(option => option.value === selectedCurrency)}
          options={currencyOptions}
          onChange={(option) => setSelectedCurrency(option?.value ?? selectedCurrency)}
          isSearchable={false}
          blurInputOnSelect
          withDescription
        />
        <DropdownSelect
          label="Select Billing Period"
          value={billingPeriodOptions.find(option => option.value === selectedBillingPeriod)}
          options={billingPeriodOptions}
          onChange={(option) => setBillingPeriod(option?.value as 'month' | 'year' ?? selectedBillingPeriod)}
          isSearchable={false}
          blurInputOnSelect
          withDescription
        />
      </div>

      <div className={styles.plans}>
        {Object.values(productPrices).map((product) => (
          <div
            key={product.id}
            className={`${styles.plan} ${product.id === subscriptionInfo.productId && styles.currentPlanCard}`}
          >
            <h1 className={styles.planName}>{product.name}</h1>
            <ul className={styles.priceList}>
              {product.prices
                .sort((p1, p2) => p2.name.localeCompare(p1.name))
                .map((price) => (
                  <li 
                    key={price.id} 
                    className={`
                      ${styles.priceItem} 
                      ${price.interval !== selectedBillingPeriod && styles.strikethrough}
                    `}
                  >
                    {getCurrencySymbol(price.currency)}
                    {price.amount} / seat / {price.interval}
                  </li>
                ))}
            </ul>

            <button
              onClick={() => handleUpdateButtonClick(product.id)}
              disabled={product.id === subscriptionInfo.productId}
              className={styles.upgradePlan}
            >
              {product.id === subscriptionInfo.productId ? 'Subscribed' : 'Upgrade'}
            </button>

            <div className={styles.flexCol}>
              <p className={styles.title}>Includes:</p>
              <ul className={styles.features}>
                {product.featureList.map((feature, index) => (
                  <li className={styles.featureItem} key={index}>
                    <span className={styles.tick}>
                      <i className={styles.check}></i>
                    </span>
                    <p>{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div> 

      <Modal
        title={"Upgrade to " + filteredPrices.find(price => price.id === selectedPriceId)?.name}
        open={isPricingPlanModalOpen}
        onClose={() => setPricingPlanModalOpen(false)}
      >
        {selectedPriceId !== subscriptionInfo.priceId && (
          <div className={styles.UpdateAndPay}>
            <div className={styles.spaceBetween}>
              <p>New plan</p>
              <p>{billingPeriodOptions.find(option => option.value === selectedBillingPeriod)?.label} billing</p>
            </div>
            <p className={styles.title}>
              {filteredPrices.find(price => price.id === selectedPriceId)?.name}
            </p>
            <div className={styles.spaceBetween}>
              <p>
                Subscription seats: {subscriptionInfo.quantity} x {filteredPrices.find(price => price.id === selectedPriceId)?.amount}
              </p>
              <p>
                {getCurrencySymbol(subscriptionInfo.currency)}
                {(subscriptionInfo.quantity * (prices.find(price => price.id === selectedPriceId)?.amount ?? 0)).toFixed(2)}
              </p>
            </div>
            <p className={styles.title}>
              Total price: {upcomingInvoice.amountDue}
            </p>

            <TermsOfUse termsUrl="#" onChange={setTermsOfUseAccepted} />

            <div className={styles.formFooter}>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!termsOfUseAccepted}
              >
                Update and pay
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SubscriptionPlans;
