import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { AppDispatch } from 'app/store';
import { IInvoice } from './invoicesTypes';
import { fetchInvoicesBySubscriptionId } from 'api/billing/invoices';

export const getInvoicesBySubscriptionId = createAsyncThunk<
  IInvoice[],
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@invoices/getInvoices', async (id, thunkApi) => {
  try {
    const res =  await fetchInvoicesBySubscriptionId(id);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});