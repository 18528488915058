import { IInvoice } from 'features/invoices/invoicesTypes';
import { paymentsClient } from '../axiosInstance';

const invoicesURL = process.env.REACT_APP_PAYMENTS_API + '/invoices'

export const fetchInvoicesBySubscriptionId = async (subscriptionId: string) => {
    return await paymentsClient.get<IInvoice[]>(invoicesURL + `/subscriptions/${subscriptionId}`);
}

export const fetchUpcomingInvoiceBySubscriptionId = async (subscriptionId: string, priceId?: string, quantity?: number) => {
    const priceIdQuery = priceId ? `priceId=${priceId}&` : '';
    const quantityQuery = quantity ? `quantity=${quantity}` : '';

    return await paymentsClient.get<IInvoice[]>(invoicesURL + `/upcoming/subscriptions/${subscriptionId}?${priceIdQuery}${quantityQuery}`);
}
