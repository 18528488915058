import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProjectManagementIcon } from 'assets/icons/Menu/ProjectManagementIcon';
import { TeamplayLogoIcon } from 'assets/icons/Menu/TeamplayLogoIcon';
import { TimesheetsIcon } from 'assets/icons/Menu/TimesheetsIcon';
import { TimeOffIcon } from 'assets/icons/Menu/TimeOffIcon';
import { ReportsIcon } from 'assets/icons/Menu/ReportsIcon';
import { PeopleIcon } from 'assets/icons/Menu/PeopleIcon';
import { MenuIcon } from 'assets/icons/Menu/MenuIcon';
import SidebarSubMenuLinks from 'components/appLayout/Sidebar/SidebarSubMenuLinks';
import { ISubMenuLink } from 'components/appLayout/Sidebar/SidebarSubMenuLinks/SidebarSubMenuLinks';
import { permissionsUserSelector } from 'features/user/userSlice';
import SidebarLink from './SidebarLink';
import styles from './Sidebar.module.scss';
import { IconType } from '../../../constants/icons.enum';

interface SidebarProps {
  isOnMobileOpen: boolean;
  closeMobileSidebar: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isOnMobileOpen, closeMobileSidebar }) => {
  const location = useLocation();
  const permissions = useSelector(permissionsUserSelector);

  const projectsActiveTitle = /^\/projects|clients/.test(location.pathname);
  const peopleActiveTitle = /^\/members/.test(location.pathname);
  const reportsActiveTitle = /^\/reports/.test(location.pathname);
  const timeOffActiveTitle = /^\/time-off/.test(location.pathname);

  const isPeopleSectionAvailable = permissions?.some(
    (el) => el.name === 'ViewPeopleSectionNavLink'
  );
  const isClientsNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewProjectManagementClientsSubSectionNavLink'
  );
  const isPoliciesNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffPoliciesSubSectionNavLink'
  );
  const isHolidaysNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffHolidaysSubSectionNavLink'
  );
  const isTimeOffRequestsNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewTimeOffRequestsSubSectionNavLink'
  );
  const isAmountsOwedReportAvailable = permissions?.some(
    (el) => el.name === 'View Amounts owed'
  );
  const isTimeAndActivityReportAvailable = permissions?.some(
    (el) => el.name === 'View Time and activity'
  );
  const isMyAmountsOwedReportAvailable = permissions?.some(
    (el) => el.name === 'View My Amounts owed'
  );
  const isMyTimeAndActivityReportAvailable = permissions?.some(
    (el) => el.name === 'View My Time and activity'
  );

  const projectsSubMenu: ISubMenuLink[] = [
    {
      title: 'Projects',
      path: `projects`,
      isAvailable: true,
       icon: IconType.projects,
    },
    {
      title: 'Clients',
      path: `clients`,
      isAvailable: !!isClientsNavLinkAvailable,
       icon: IconType.clients,
    },
    {
      title: 'Members',
      path: `members`,
      isAvailable: true,
       icon: IconType.members,
    },
  ];
  // const peopleSubMenu: ISubMenuLink[] = [
  //   {
  //     title: 'Members',
  //     path: `members`,
  //     isAvailable: true,
  //   },
  // ];
  const reportsSubMenu: ISubMenuLink[] = [
    {
      // title: 'Time & activity',
      // path: isTimeAndActivityReportAvailable
      //   ? 'reports/time-and-activity'
      //   : 'reports/time-and-activity/my',
      // isAvailable: !!isMyTimeAndActivityReportAvailable || !!isTimeAndActivityReportAvailable,

      title: 'Time & activity', // Time And Activity report is TEMPORARILY hidden for the user role
      path: 'reports/time-and-activity',
      isAvailable: !!isTimeAndActivityReportAvailable,
      icon: IconType.clientReport,
    },
    {
      title: 'Amounts owed',
      path: isAmountsOwedReportAvailable
        ? 'reports/amounts-owed'
        : 'reports/amounts-owed/my',
      isAvailable: !!isMyAmountsOwedReportAvailable || !!isAmountsOwedReportAvailable,
      icon: IconType.employeeReport,
    },
    // {
    //   title: 'All reports',
    //   path: 'reports/all-reports',
    //   isAvailable: true,
    // },
  ];

  const timeOffSubMenu: ISubMenuLink[] = [
    {
      title: 'Policies',
      path: `time-off/policies`,
      isAvailable: !!isPoliciesNavLinkAvailable,
      icon: IconType.policies,
    },
    {
      title: 'Holidays',
      path: `time-off/holidays`,
      isAvailable: !!isHolidaysNavLinkAvailable,
      icon: IconType.holidays,
    },
    {
      title: 'Requests',
      path: `time-off/requests`,
      isAvailable: !!isTimeOffRequestsNavLinkAvailable,
      icon: IconType.requests,
    },
  ];

  return (
    <>
      <aside
        className={`${styles.aside} ${
          isOnMobileOpen ? styles.showMobileSidebar : ''
        }`}
      >

        <div className={`${styles.asideBody}`}>
          <div className={styles.asideHeader}>
            <Link to="/" aria-label="Main page link" className={styles.logo}>
              <TeamplayLogoIcon />
            </Link>
            <button
              aria-label="Close sidebar menu"
              className={styles.menu}
              onClick={closeMobileSidebar}
            >
              <MenuIcon />
            </button>
          </div>
          <ul>
            <SidebarLink
              path={`time-entries`}
              title="Timesheets"
              icon={<TimesheetsIcon />}
            />
            <SidebarSubMenuLinks
              menuTitle="Managment"
              icon={<ProjectManagementIcon />}
              subMenuItemsData={projectsSubMenu}
              activeTitle={projectsActiveTitle}
            />
            {/*{!!isPeopleSectionAvailable && (*/}
            {/*  <SidebarSubMenuLinks*/}
            {/*    menuTitle="People"*/}
            {/*    icon={<PeopleIcon />}*/}
            {/*    subMenuItemsData={peopleSubMenu}*/}
            {/*    activeTitle={peopleActiveTitle}*/}
            {/*  />*/}
            {/*)}*/}
            <SidebarSubMenuLinks
              menuTitle="Reports"
              icon={<ReportsIcon />}
              subMenuItemsData={reportsSubMenu}
              activeTitle={reportsActiveTitle}
            />
            <SidebarSubMenuLinks
              menuTitle="Time off"
              icon={<TimeOffIcon />}
              subMenuItemsData={timeOffSubMenu}
              activeTitle={timeOffActiveTitle}
            />
          </ul>
        </div>
      </aside>
      <div
        className={isOnMobileOpen ? styles.backDropOn : styles.backDropOff}
        onClick={closeMobileSidebar}
      />
    </>
  );
};

export default Sidebar;
