import React, { useEffect } from "react";
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/store";
import { getInvoicesBySubscriptionId } from "features/invoices/invoicesActions";
import { subscriptionInfoSelector } from "features/subscription/subscriptionSlice";
import { invoicesSelector } from "features/invoices/invoicesSlice";
import { IInvoice, InvoiceTableHeadings } from "features/invoices/invoicesTypes";
import { PulseLoader } from "react-spinners";
import { DownloadIcon } from "assets/icons/Menu/DownloadIcon";
import { getCurrencySymbol } from "helpers/getCurrencySymbol";

const invoiceTableColumns: Record<'name', InvoiceTableHeadings>[] = [
  { name: 'Date' },
  { name: 'Amount' },
  { name: 'Description' },
  { name: 'Status' },
  { name: 'Download' }
];

const Invoices: React.FC = () => {
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const invoiceInfo = useSelector(invoicesSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subscriptionInfo?.id) {
      dispatch(getInvoicesBySubscriptionId(subscriptionInfo.id));
    }
  }, [subscriptionInfo, dispatch]);

  const InvoiceTableItem: React.FC<{ invoice: IInvoice }> = ({ invoice }) => {
    return (
      <div className={`${styles.fRow} ${styles.fBodyRow}`} key={invoice.id}>
        <div className={`${styles.fCell} ${styles.fBodyCell}`}>
          <span className={`${styles.fBodyCellContent}`}>
            {(new Date(invoice.date)).toDateString()}
          </span>
        </div>
        <div className={`${styles.fCell} ${styles.fBodyCell}`}>
          <span className={`${styles.fBodyCellContent}`}>
            {getCurrencySymbol(invoice.currency)}{invoice.amount ?? 0}
          </span>
        </div>
        <div className={`${styles.fCell} ${styles.fBodyCell}`}>
          <span className={`${styles.fBodyCellContent}`}>
            {invoice.description}
          </span>
        </div>
        <div className={`${styles.fCell} ${styles.fBodyCell}`}>
          <span className={`${styles.fBodyCellContent}`}>
            {invoice.status}
          </span>
        </div>
        <div className={`${styles.fCell} ${styles.fBodyCell}`}>
          <span className={`${styles.fBodyCellContent}`}>
            <a href={invoice.downloadPdfUrl}><DownloadIcon/></a>
          </span>
        </div>
      </div>
    );
  };

  if (invoiceInfo.isLoading)
    return (
      <div className={styles.loadNextTableIndicator}>
        <PulseLoader color="#0168fa" size={8} margin={5} speedMultiplier={1} />
      </div>
    );

  return (
    <div className={styles.fTable}>
      <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
        {invoiceTableColumns.map((col) => (
          <div key={col.name} className={`${styles.fCell} ${styles.fHeaderCell}`}>
            {col.name}
          </div>
        ))}
      </div>
      <div className={styles.fTableBody}>
        {invoiceInfo.invoices.map((invoice) => (
          <InvoiceTableItem invoice={invoice} key={invoice.id} />
        ))}
      </div>
    </div>
  );
};

export default Invoices;
