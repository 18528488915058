import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from 'assets/images/404PageLogo.png';
import Button from 'components/shared/Button';
import styles from './NotFound404.module.scss';

const NotFound404: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <p>
        Sorry, the content you’re looking for doesn’t exist
      </p>
      <img src={NotFoundImage} alt="404 Not Found Image" />
      <Button variant="primary" onClick={() => navigate('/')}>
        Go to Home
      </Button>
    </div>
  );
};

export default NotFound404;
