import { paymentsClient } from '../axiosInstance';
import { ICustomer } from 'features/customer/customerTypes';

const customersURL = process.env.REACT_APP_PAYMENTS_API + '/customers'

export const fetchCustomerById = async (customerId: string) => {
    return await paymentsClient.get<ICustomer>(customersURL + `/${customerId}`);
}

export const fetchCustomerByOrganozationId = async (organozationId: number) => {
    return await paymentsClient.get<ICustomer>(customersURL + `/organizations/${organozationId}`);
}

