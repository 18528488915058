import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { AppDispatch } from 'app/store';
import { fetchUpcomingInvoiceBySubscriptionId } from 'api/billing/invoices';
import { IUpcomingInvoice } from './upcomingInvoiceTypes';

export const getUpcomingInvoiceBySubscriptionId = createAsyncThunk<
  IUpcomingInvoice,
  { subscriptionId: string, priceId?: string, quantity?: number },
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@upcomingInvoice/getUpcomingInvoice', async ({ subscriptionId, priceId, quantity }, thunkApi) => {
  try {
    const res =  await fetchUpcomingInvoiceBySubscriptionId(subscriptionId, priceId, quantity);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});