import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { InvoiceSliceInitialState } from './invoicesTypes';
import { getInvoicesBySubscriptionId } from './invoicesActions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';

const initialState: InvoiceSliceInitialState = {
  invoices: [],
  isLoading: false,
};

export const invoiceSlice = createSlice({
  name: '@@invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoicesBySubscriptionId.fulfilled, (state, action) => {
      state.invoices = action.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const invoicesSelector = createSelector(
  [(state: RootState) => state.invoices],
  (info) => info
);
