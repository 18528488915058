import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { AppDispatch } from 'app/store';
import { ICustomer } from './customerTypes';
import { fetchCustomerById, fetchCustomerByOrganozationId } from 'api/billing/customers';

export const getCustomerById = createAsyncThunk<
  ICustomer,
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@customer/getCustomerById', async (id, thunkApi) => {
  try {
    const res =  await fetchCustomerById(id);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getCustomerByOrganizationId = createAsyncThunk<
  ICustomer,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@customer/getCustomerByOrganozationId', async (id, thunkApi) => {
  try {
    const res =  await fetchCustomerByOrganozationId(id);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
