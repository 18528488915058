import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';
import { getUpcomingInvoiceBySubscriptionId } from './upcomingInvoiceActions';
import { IUpcomingInvoice, UpcomingInvoiceliceInitialState } from './upcomingInvoiceTypes';

const initialState: UpcomingInvoiceliceInitialState = {
  upcomingInvoice: {} as IUpcomingInvoice,
  isLoading: false,
};

export const upcomingInvoiceSlice = createSlice({
  name: '@@upcomingInvoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUpcomingInvoiceBySubscriptionId.fulfilled, (state, action) => {
      state.upcomingInvoice = action.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const upcomingInvoiceSelector = createSelector(
  [(state: RootState) => state.upcomingInvoice.upcomingInvoice],
  (info) => info
);
