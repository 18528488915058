import React from 'react';

export const MenuIcon = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 12.5C0.533333 12.5 0.354167 12.4292 0.2125 12.2875C0.0708334 12.1458 0 11.9667 0 11.75C0 11.5333 0.0708334 11.3542 0.2125 11.2125C0.354167 11.0708 0.533333 11 0.75 11H17.25C17.4667 11 17.6458 11.0708 17.7875 11.2125C17.9292 11.3542 18 11.5333 18 11.75C18 11.9667 17.9292 12.1458 17.7875 12.2875C17.6458 12.4292 17.4667 12.5 17.25 12.5H0.75ZM0.75 7.25C0.533333 7.25 0.354167 7.17917 0.2125 7.0375C0.0708334 6.89583 0 6.71667 0 6.5C0 6.28333 0.0708334 6.10417 0.2125 5.9625C0.354167 5.82083 0.533333 5.75 0.75 5.75H17.25C17.4667 5.75 17.6458 5.82083 17.7875 5.9625C17.9292 6.10417 18 6.28333 18 6.5C18 6.71667 17.9292 6.89583 17.7875 7.0375C17.6458 7.17917 17.4667 7.25 17.25 7.25H0.75ZM0.75 2C0.533333 2 0.354167 1.92917 0.2125 1.7875C0.0708334 1.64583 0 1.46667 0 1.25C0 1.03333 0.0708334 0.854167 0.2125 0.7125C0.354167 0.570833 0.533333 0.5 0.75 0.5H17.25C17.4667 0.5 17.6458 0.570833 17.7875 0.7125C17.9292 0.854167 18 1.03333 18 1.25C18 1.46667 17.9292 1.64583 17.7875 1.7875C17.6458 1.92917 17.4667 2 17.25 2H0.75Z"
        fill="#000"
      />
    </svg>
  );
};
