import React, { useEffect } from "react";
import styles from "./Billing.module.scss";
import SubscriptionDetails from "components/billing/SubscriptionDetails";
import Button from "components/shared/Button";
import { redirectToBillingPortalPaymentMethods } from "api/billing/sessions";
import { useSelector } from "react-redux";
import { selectInfoUserSelector } from "features/user/userSlice";
import { useAppDispatch } from "app/store";
import { getCustomerByOrganizationId } from "features/customer/customerActions";
import { customerInfoSelector } from "features/customer/customerSlice";

const Billing: React.FC = () => {
  const dispatch = useAppDispatch();

  const userInfo = useSelector(selectInfoUserSelector);
  const customerInfo = useSelector(customerInfoSelector)

    useEffect(() => {
      if (userInfo?.organizationId) {
        dispatch(getCustomerByOrganizationId(userInfo.organizationId));
      }
    }, [userInfo]);
  
  return (
    <div className={styles.container}>
      <div className={styles.billingInfo}>
        <div className={styles.segment}>
          <div>
            <h2>Payment Information</h2>
            {customerInfo.paymentMethodType === 'card' ?
              <div>
                <p>Card ({customerInfo.cardBrand}): **** **** **** {customerInfo.cardLast4}</p>
                <p>Expiration date: {customerInfo.cardExpMonth}/{customerInfo.cardExpYear}</p>
              </div>
              :
              <p>payment method type: {customerInfo.paymentMethodType}</p>
            }
            <p>
              You will be automatically charged for the subscription once your free
              trial ends
            </p>
          </div>
            
          <Button
            aria-label="Add payment method"
            variant="primary"
            style={{maxWidth: "200px"}}
            onClick={() => redirectToBillingPortalPaymentMethods(userInfo?.organizationId)}
          >
            Add payment method
          </Button>
        </div>
        <div className={styles.segment}>
          <div>
            <h2>Customer Information</h2>
            
            <p>Invoices sent to: {customerInfo.email}</p>
            <p>Country: {customerInfo.country}</p>
            <p>City: {customerInfo.city}</p>
            <p>Address: {customerInfo.addressLine1}</p>
            <p>Address 2: {customerInfo.addressLine2}</p>
            <p>ZIP / Postal code: {customerInfo.postalCode}</p>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <SubscriptionDetails/>
      </div>
    </div>
  );
};

export default Billing;
