import { User } from 'oidc-client';

export enum USER_ROLE {
  Owner = 'Owner',
  Admin = 'Admin',
  // TODO: Uncomment this code once the manager role is fully restored
  // Manager = 'Manager', 
  User = 'User',
  Viewer = 'Viewer',
}
export enum UserPayType {
  Hourly = 'Hourly',
  Fixed = 'Fixed',
}

export interface IUser {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: keyof typeof USER_ROLE;
  organizationId: number;
  timeZone: ITimeZone;
  permissions: IGeneralUserPermission[];
  firstDayOfWeek: 0 | 1;
}

export interface IGeneralUserPermission {
  id: number;
  name: string;
  scopes: number | null;
  entityType: number | null;
  containerType: number | null;
}

export interface UserSliceInitialState {
  OIDCUser: User | null;
  userInfo: IUser | null;
  isLoading: boolean;
  timeZonesList: ITimeZone[];
  isAuthenticated: boolean;
  errorMessage: string | unknown;
}

export interface ITimeZone {
  id: string;
  name: string;
  greenwichMeanTime: string;
}

export type IUpdateUserInfo = Pick<
  IUser,
  'firstName' | 'lastName' | 'phoneNumber' | 'timeZone' | 'firstDayOfWeek'
>;

export enum FirstDayOfWeek {
  'Sunday' = 0,
  'Monday' = 1,
}
