import React, { FC } from 'react';
import styles from './Navbar.module.scss';
import { MenuIcon } from 'assets/icons/Menu/MenuIcon';
import AccountMenu from 'components/account/AccountMenu';
import PageTitle from 'components/shared/PageTitle';
import { useLocation } from 'react-router-dom';

interface NavbarProps {
  onMobileMenuClick: () => void;
}

const Navbar: FC<NavbarProps> = ({ onMobileMenuClick }) => {
  const { pathname } = useLocation();

  const title = () => {
    if (pathname.startsWith('/projects')) return 'Projects';
    if (pathname.startsWith('/clients')) return 'Clients';
    if (pathname.startsWith('/members/invites')) return 'Invites';
    if (pathname.startsWith('/members')) return 'Members';
    if (pathname.startsWith('/time-entries')) return 'Timesheets';
    if (pathname.startsWith('/account')) return 'Account';
    if (pathname.startsWith('/reports/all-reports')) return 'All reports';
    if (pathname.startsWith('/reports/amounts-owed')) return 'Amounts owed';
    if (pathname.startsWith('/reports/time-and-activity'))
      return 'Time & activity';
    if (pathname.startsWith('/time-off/requests')) return 'Time off requests';
    if (pathname.startsWith('/time-off/policies')) return 'Policies';
    if (pathname.startsWith('/time-off/holidays')) return 'Holidays';
    if (pathname.startsWith('/billing')) return 'Billing information';
    return '';
  };

  return (
    <>
      <nav className={styles.root}>
        <button
          aria-label="Open sidebar menu"
          className={styles.menuWrapper}
          onClick={onMobileMenuClick}
        >
          <MenuIcon />
        </button>
        <div className={styles.titleWrapper}>
          <PageTitle title={title()} />
        </div>
        <div className={styles.accountWrapper}>
          <AccountMenu />
        </div>
      </nav>
    </>
  );
};

export default React.memo(Navbar);
