import React from 'react';

export const TeamplayLogoIcon = () => {
  return (
    <svg width="169"    
      height="32" 
      viewBox="0 0 169 32"    
      fill="none"     
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2236_293)">
        <path d="M6.90022 30.7962C5.19998 30.7962 3.82166 29.4179 3.82166 27.7177V9.42322C3.82166 7.72298 5.19998 6.34466 6.90022 6.34466C8.60046 6.34466 9.97878 7.72298 9.97878 9.42322V27.7177C9.97878 29.4179 8.60046 30.7962 6.90022 30.7962ZM2.56547 18.6235C1.1486 18.6235 0 17.4749 0 16.0581C0 14.6412 1.1486 13.4926 2.56547 13.4926H11.235C12.6518 13.4926 13.8004 14.6412 13.8004 16.0581C13.8004 17.4749 12.6518 18.6235 11.235 18.6235H2.56547Z"
          fill="#fff" />
        <path d="M20.8124 30.7962C19.1121 30.7962 17.7338 29.4179 17.7338 27.7177V16.5711C17.7338 14.8709 19.1121 13.4926 20.8124 13.4926C22.5126 13.4926 23.8909 14.8709 23.8909 16.5711V27.7177C23.8909 29.4179 22.5126 30.7962 20.8124 30.7962ZM23.8909 21.3836L21.1308 19.0481C21.815 17.0901 22.7232 15.6157 23.8555 14.6249C24.9879 13.6341 26.4623 13.1387 28.2787 13.1387C29.1044 13.1387 29.8121 13.2449 30.4019 13.4572C31.4194 13.8094 31.4874 15.0781 30.8424 15.9402L28.9622 18.4534C28.6641 18.852 28.0432 18.8871 27.571 18.7297C27.2408 18.5881 26.8633 18.5174 26.4387 18.5174C25.6366 18.5174 25.0115 18.7651 24.5632 19.2605C24.115 19.7323 23.8909 20.44 23.8909 21.3836Z"
          fill="#fff" />
        <path d="M41.6847 31.1501C40.0805 31.1501 38.6533 30.7608 37.403 29.9823C36.1527 29.2039 35.1737 28.1423 34.466 26.7976C33.7583 25.4294 33.4044 23.8842 33.4044 22.1621C33.4044 20.4164 33.7583 18.8594 34.466 17.4912C35.1737 16.1229 36.1527 15.0614 37.403 14.3065C38.6533 13.528 40.0805 13.1387 41.6847 13.1387C42.7699 13.1387 43.7607 13.3392 44.6571 13.7403C45.5535 14.1177 46.2848 14.6603 46.851 15.368C47.4408 16.0757 47.7946 16.8778 47.9126 17.7743V26.4792C47.7946 27.3992 47.4408 28.2131 46.851 28.9208C46.2848 29.6049 45.5535 30.1475 44.6571 30.5485C43.7607 30.9496 42.7699 31.1501 41.6847 31.1501ZM42.994 25.6653C44.0084 25.6653 44.8222 25.335 45.4356 24.6745C46.0725 24.0139 46.391 23.1647 46.391 22.1267C46.391 21.4426 46.2495 20.841 45.9664 20.322C45.6833 19.803 45.2823 19.3902 44.7633 19.0835C44.2679 18.7769 43.6899 18.6235 43.0294 18.6235C42.3688 18.6235 41.7791 18.7769 41.2601 19.0835C40.7647 19.3902 40.3636 19.8148 40.057 20.3574C39.7739 20.8764 39.6323 21.4662 39.6323 22.1267C39.6323 22.8108 39.7739 23.4242 40.057 23.9668C40.34 24.4858 40.7411 24.8986 41.2601 25.2053C41.7791 25.5119 42.357 25.6653 42.994 25.6653ZM49.1688 30.7962C47.4979 30.7962 46.1433 29.4417 46.1433 27.7707V26.1607L47.028 21.9144L46.1433 17.7389V16.5181C46.1433 14.8471 47.4979 13.4926 49.1688 13.4926C50.8397 13.4926 52.1943 14.8471 52.1943 16.5181V27.7707C52.1943 29.4417 50.8397 30.7962 49.1688 30.7962Z"
          fill="#fff" />
        <path d="M66.1199 31.1855C64.2799 31.1855 62.6168 30.7962 61.1306 30.0177C59.6679 29.2392 58.512 28.1659 57.6628 26.7976C56.8135 25.4294 56.3889 23.8842 56.3889 22.1621C56.3889 20.4164 56.8135 18.8594 57.6628 17.4912C58.5356 16.1229 59.7033 15.0496 61.1659 14.2711C62.6521 13.469 64.3271 13.068 66.1907 13.068C67.5354 13.068 68.7621 13.2921 69.8708 13.7403C70.1499 13.8531 70.423 13.9801 70.6902 14.1213C72.0376 14.8337 72.0329 16.6384 70.9552 17.7161L70.0649 18.6064C69.4887 19.1826 68.5522 19.1404 67.7831 18.8712C67.3349 18.6825 66.8041 18.5881 66.1907 18.5881C65.5302 18.5881 64.9286 18.7415 64.386 19.0481C63.8671 19.3312 63.4424 19.7323 63.1122 20.2513C62.8055 20.7703 62.6521 21.3954 62.6521 22.1267C62.6521 22.8344 62.8055 23.4596 63.1122 24.0022C63.4424 24.5211 63.8671 24.934 64.386 25.2407C64.9286 25.5473 65.5302 25.7007 66.1907 25.7007C66.8513 25.7007 67.4174 25.5945 67.8892 25.3822C68.6586 25.036 69.6328 24.9627 70.232 25.5565L71.132 26.4483C72.189 27.4959 72.1998 29.2545 70.905 29.9883C70.5796 30.1728 70.2466 30.3359 69.9062 30.4777C68.7975 30.9496 67.5354 31.1855 66.1199 31.1855Z"
          fill="#fff" />
        <path d="M89.8025 30.7962C88.7861 30.7962 87.844 30.2635 87.32 29.3925L82.715 21.7375L87.3269 14.7142C87.8275 13.9518 88.6784 13.4926 89.5905 13.4926C91.7999 13.4926 93.0795 15.9956 91.7857 17.7865L88.1644 22.799L88.3414 20.5343L92.2066 26.2826C93.5006 28.2071 92.1216 30.7962 89.8025 30.7962ZM80.1319 30.7962C78.4316 30.7962 77.0533 29.4179 77.0533 27.7177V8.07857C77.0533 6.37832 78.4316 5 80.1319 5C81.8321 5 83.2104 6.37832 83.2104 8.07857V27.7177C83.2104 29.4179 81.8321 30.7962 80.1319 30.7962Z"
          fill="#fff" />
        <path d="M106.811 31.1855C104.876 31.1855 103.166 30.808 101.68 30.0531C100.194 29.2746 99.0259 28.2013 98.1767 26.833C97.3274 25.4648 96.9028 23.896 96.9028 22.1267C96.9028 20.381 97.3156 18.8358 98.1413 17.4912C98.967 16.1229 100.088 15.0496 101.503 14.2711C102.942 13.469 104.558 13.068 106.351 13.068C108.096 13.068 109.642 13.4454 110.986 14.2003C112.355 14.9316 113.416 15.9578 114.171 17.2789C114.95 18.5763 115.339 20.0625 115.339 21.7375C115.339 22.0913 115.315 22.457 115.268 22.8344C115.219 23.573 114.538 24.0753 113.798 24.0788L99.8044 24.1437V20.1805L112.614 20.0743L109.854 21.8082C109.83 20.841 109.689 20.0507 109.429 19.4374C109.17 18.8004 108.792 18.3168 108.297 17.9866C107.802 17.6563 107.176 17.4912 106.422 17.4912C105.643 17.4912 104.971 17.6799 104.405 18.0573C103.838 18.4348 103.402 18.9656 103.095 19.6497C102.789 20.3338 102.635 21.1595 102.635 22.1267C102.635 23.1175 102.8 23.9668 103.131 24.6745C103.461 25.3586 103.933 25.8776 104.546 26.2315C105.159 26.5853 105.914 26.7622 106.811 26.7622C107.66 26.7622 108.403 26.6207 109.04 26.3376C110.145 25.864 111.529 25.7477 112.379 26.5978L112.523 26.7418C113.37 27.5893 113.429 28.9847 112.431 29.6476C111.963 29.9586 111.457 30.2235 110.916 30.4424C109.712 30.9378 108.344 31.1855 106.811 31.1855Z"
          fill="#fff" />
        <path d="M122.512 30.7962C120.812 30.7962 119.433 29.4179 119.433 27.7177V16.5711C119.433 14.8709 120.812 13.4926 122.512 13.4926C124.212 13.4926 125.59 14.8709 125.59 16.5711V27.7177C125.59 29.4179 124.212 30.7962 122.512 30.7962ZM125.59 21.3836L122.83 19.0481C123.514 17.0901 124.423 15.6157 125.555 14.6249C126.687 13.6341 128.162 13.1387 129.978 13.1387C130.804 13.1387 131.512 13.2449 132.101 13.4572C133.119 13.8094 133.187 15.0781 132.542 15.9402L130.662 18.4534C130.364 18.852 129.743 18.8871 129.271 18.7297C128.94 18.5881 128.563 18.5174 128.138 18.5174C127.336 18.5174 126.711 18.7651 126.263 19.2605C125.815 19.7323 125.59 20.44 125.59 21.3836Z"
          fill="#fff" />
        <path d="M143.384 31.1501C141.78 31.1501 140.353 30.7608 139.103 29.9823C137.852 29.2039 136.873 28.1423 136.166 26.7976C135.458 25.4294 135.104 23.8842 135.104 22.1621C135.104 20.4164 135.458 18.8594 136.166 17.4912C136.873 16.1229 137.852 15.0614 139.103 14.3065C140.353 13.528 141.78 13.1387 143.384 13.1387C144.469 13.1387 145.46 13.3392 146.357 13.7403C147.253 14.1177 147.984 14.6603 148.551 15.368C149.14 16.0757 149.494 16.8778 149.612 17.7743V26.4792C149.494 27.3992 149.14 28.2131 148.551 28.9208C147.984 29.6049 147.253 30.1475 146.357 30.5485C145.46 30.9496 144.469 31.1501 143.384 31.1501ZM144.693 25.6653C145.708 25.6653 146.522 25.335 147.135 24.6745C147.772 24.0139 148.091 23.1647 148.091 22.1267C148.091 21.4426 147.949 20.841 147.666 20.322C147.383 19.803 146.982 19.3902 146.463 19.0835C145.967 18.7769 145.389 18.6235 144.729 18.6235C144.068 18.6235 143.479 18.7769 142.96 19.0835C142.464 19.3902 142.063 19.8148 141.756 20.3574C141.473 20.8764 141.332 21.4662 141.332 22.1267C141.332 22.8108 141.473 23.4242 141.756 23.9668C142.04 24.4858 142.441 24.8986 142.96 25.2053C143.479 25.5119 144.057 25.6653 144.693 25.6653ZM150.868 30.7962C149.197 30.7962 147.843 29.4417 147.843 27.7707V26.1607L148.727 21.9144L147.843 17.7389V16.5181C147.843 14.8471 149.197 13.4926 150.868 13.4926C152.539 13.4926 153.894 14.8471 153.894 16.5181V27.7707C153.894 29.4417 152.539 30.7962 150.868 30.7962Z"
          fill="#fff" />
        <circle cx="161.394" 
          cy="8" 
          r="7.5" 
          fill="url(#paint0_linear_2236_293)" />
          <circle cx="161.394" 
            cy="8" 
            r="7.5" 
            fill="url(#paint1_linear_2236_293)" />
          <path d="M168.894 8C168.894 3.85786 165.536 0.5 161.394 0.5V8L166.697 13.3033C168.054 11.9461 168.894 10.0711 168.894 8Z"
            fill="url(#paint2_linear_2236_293)" />
          <path d="M168.894 8C168.894 3.85786 165.536 0.5 161.394 0.5V8L166.697 13.3033C168.054 11.9461 168.894 10.0711 168.894 8Z"
            fill="url(#paint3_linear_2236_293)" />
          <path d="M161.394 0.5C157.252 0.5 153.894 3.85786 153.894 8H161.394V0.5Z"
            fill="url(#paint4_linear_2236_293)" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_2236_293" x1="161.394" y1="4.56912" x2="161.394" y2="15.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1C10A7" />
            <stop offset="1" stopColor="#2012BD" />
          </linearGradient>
          <linearGradient id="paint1_linear_2236_293" x1="155.063" y1="13.1806" x2="161.394" y2="8" gradientUnits="userSpaceOnUse">
            <stop stopColor="#180E8C" />
            <stop offset="1" stopColor="#180E8C" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="paint2_linear_2236_293" x1="161.394" y1="2.45922" x2="164.369" y2="6.47238" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2815FA" />
            <stop offset="1" stopColor="#2815FA" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="paint3_linear_2236_293" x1="167.25" y1="3.48328" x2="162.541" y2="9.82051" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2916FD" />
            <stop offset="1" stopColor="#2916FD" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="paint4_linear_2236_293" x1="158.963" y1="0.5" x2="158.858" y2="7.37305" gradientUnits="userSpaceOnUse">
            <stop stopColor="#100A52" />
            <stop offset="1" stopColor="#100A52" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_2236_293">
            <rect width="169" height="32" fill="white" />
          </clipPath>
      </defs>
    </svg>
  );
};
