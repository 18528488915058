import React, { useEffect } from 'react';
import styles from './Subscription.module.scss';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { getSubscriptionInfoByOrganozationId } from 'features/subscription/subscriptionActions';
import { getMembers } from 'features/members/membersActions';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { getPrices } from 'features/prices/pricesActions';
import PageTabs, { IPageTab } from 'components/shared/PageTabs/PageTabs';
import { Outlet } from 'react-router-dom';

const Subscription: React.FC = () => {
  const userInfo = useSelector(selectInfoUserSelector);
  const dispatch = useAppDispatch();

  const tabsData: IPageTab[] = [
      {
        path: `/subscription`,
        name: 'Billing',
      },
      {
        path: `/subscription/invoices`,
        name: 'Invoices',
      },
      {
        path: `/subscription/plans`,
        name: 'Plans',
      },
    ];

  useEffect(() => {
    if (userInfo?.organizationId) {
      dispatch(getSubscriptionInfoByOrganozationId(userInfo.organizationId));
      dispatch(getMembers());
      dispatch(getPrices())
    }
  }, [userInfo]);

  return (
    <div className={styles.billing}>
      <PageTabs tabsData={tabsData} />
      <Outlet/>
    </div>
  );
};

export default Subscription;
