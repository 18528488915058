import React, { useEffect, useState } from 'react';
import styles from './SubscriptionDetails.module.scss';
import Button from 'components/shared/Button';
import { useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { IUpdateSubscriptionData } from 'features/subscription/subscriptionTypes';
import { getSubscriptionInfoByOrganozationId, updateSubscriptionInfo } from 'features/subscription/subscriptionActions';
import { pricesSelector } from 'features/prices/pricesSlice';
import TermsOfUse from 'components/shared/TermsOfUse';
import { getCurrencySymbol } from 'helpers/getCurrencySymbol';
// import { itemsTotalCountMembersSelector } from 'features/members/membersSlice';
import { getMembers } from 'features/members/membersActions';
import { getPrices } from 'features/prices/pricesActions';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { getUpcomingInvoiceBySubscriptionId } from 'features/upcomingInvoice/upcomingInvoiceActions';
import { upcomingInvoiceSelector } from 'features/upcomingInvoice/upcomingInvoiceSlice';
import Modal from 'components/shared/Modal';

const EditSubscriptionDetailsForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectInfoUserSelector);
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  // const membersCount = useSelector(itemsTotalCountMembersSelector);
  const prices = useSelector(pricesSelector);
  const upcomingInvoice = useSelector(upcomingInvoiceSelector);

  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);
  const [subscriptionSeats, setSubscriptionSeats] = useState<number>(subscriptionInfo.quantity);
  const [billingPeriod, setBillingPeriod] = useState<'month' | 'year'>(subscriptionInfo.interval);
  const [priceBreakdownIsShown, setPriceBreakdownIsShown] = useState(false);

  useEffect(() => {
    dispatch(getMembers());
    dispatch(getPrices())
  }, []);

  useEffect(() => {
    if (userInfo?.organizationId) {
      dispatch(getSubscriptionInfoByOrganozationId(userInfo.organizationId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (subscriptionInfo?.id) {
      dispatch(getUpcomingInvoiceBySubscriptionId(
        {
          subscriptionId: subscriptionInfo.id,
          priceId: subscriptionInfo.priceId,
          quantity: subscriptionInfo.quantity
        }
      ))
      setSubscriptionSeats(subscriptionInfo.quantity);
      setBillingPeriod(subscriptionInfo.interval); 
    }
  }, [subscriptionInfo]);
  
  const handleIncrement = () => 
  {
    setSubscriptionSeats(prev => prev + 1);
    dispatch(getUpcomingInvoiceBySubscriptionId(
      {
        subscriptionId: subscriptionInfo.id,
        quantity: subscriptionSeats + 1
      }
    ))
  }
  const handleDecrement = () => 
  {
    setSubscriptionSeats(prev => (prev > 1 ? prev - 1 : 1));
    dispatch(getUpcomingInvoiceBySubscriptionId(
      {
        subscriptionId: subscriptionInfo.id,
        quantity: subscriptionSeats - 1
      }
    ))
  }

  const handleSubmit = () => {
    const currentPrice = prices.find(x => x.id === subscriptionInfo.priceId);
    const newPrice = prices.find(x => 
      x.id !== currentPrice?.id &&
      x.productId === currentPrice?.productId &&
      x.currency === subscriptionInfo.currency
    )

    const updateSubscriptionData: IUpdateSubscriptionData = {
      subscriptionId: subscriptionInfo.id,
      priceId: subscriptionInfo.priceId, 
      quantity: subscriptionSeats, 
    }

    if(newPrice && subscriptionInfo.interval !== billingPeriod) 
    {
      updateSubscriptionData.priceId = newPrice.id;
    }

    dispatch(updateSubscriptionInfo(updateSubscriptionData));
    setTermsOfUseAccepted(false)
  }

  return (
    <div className={styles.subscriptionDetails}>
      <div className={styles.planSummary}>
        <div className={styles.flexCol}>
          <p>Current plan:</p>
          <span className={styles.currentPlan}>{subscriptionInfo.name}</span>
        </div>

        <div className={styles.spaceBetween}>
          <p>
            Subscription seats: {subscriptionSeats} x {prices.find(price => price.id === subscriptionInfo.priceId)?.amount}
          </p>
          <p>
            {getCurrencySymbol(subscriptionInfo.currency)}
            {(subscriptionSeats * (prices.find(price => price.id === subscriptionInfo.priceId)?.amount ?? 0)).toFixed(2)}
          </p>
        </div>

        <div className={styles.flexCol}>
          <p>Total update price:</p>
          <span className={styles.totalPrice}>
            {getCurrencySymbol(subscriptionInfo.currency)}{subscriptionSeats !== subscriptionInfo.quantity ? upcomingInvoice.amountDue : 0}
          </span>
        </div>

        <p>Next billing date: {(new Date(subscriptionInfo.periodEnd)).toDateString()}</p>
        {subscriptionInfo.trialEnd &&
          <p>Free trial end date: {(new Date(subscriptionInfo.trialEnd)).toDateString()}</p>
        }
        {subscriptionSeats !== subscriptionInfo.quantity && 
          <Button
            variant='default'
            onClick={() => setPriceBreakdownIsShown(true)}
          >
            View price breakdown
          </Button>
        }

        {/*<ul>*/}
        {/*  <li>✔ {subscriptionInfo.name}</li>*/}
        {/*  <li>*/}
        {/*    ✔{getCurrencySymbol(subscriptionInfo.currency)}*/}
        {/*    {subscriptionInfo.noTaxAmount ?? 0} +{' '}*/}
        {/*    {getCurrencySymbol(subscriptionInfo.currency)}*/}
        {/*    {subscriptionInfo.taxAmount ?? 0} VAT*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    ✔ Total: {getCurrencySymbol(subscriptionInfo.currency)}{' '}*/}
        {/*    {subscriptionInfo.totalAmount ?? 0}*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    ✔ {billingPeriod === 'month' ? 'Monthly' : 'Annual'} billing cycle*/}
        {/*  </li>*/}
        {/*  <li>✔ {subscriptionSeats} subscription seats</li>*/}
        {/*  <li>✔ {membersCount} active members</li>*/}
        {/*</ul>*/}
      </div>
      {/*<div className={styles.billingToggle}>*/}
      {/*  <Button*/}
      {/*    variant={billingPeriod === 'month' ? 'primary' : 'default'}*/}
      {/*    onClick={() => handlePeriodChange('month')}*/}
      {/*  >*/}
      {/*    Monthly*/}
      {/*  </Button>*/}
      {/*  <Button*/}
      {/*    variant={billingPeriod === 'year' ? 'primary' : 'default'}*/}
      {/*    onClick={() => handlePeriodChange('year')}*/}
      {/*  >*/}
      {/*    Annual*/}
      {/*  </Button>*/}
      {/*</div>*/}


      <div className={styles.teamMembersControl}>
        <label className={styles.memberName}>User seats:</label>
        <button className={styles.counterButton} onClick={handleDecrement}>-</button>
        <input type="text" value={subscriptionSeats} readOnly />
        <button className={styles.counterButton} onClick={handleIncrement}>+</button>
      </div>

      {(subscriptionSeats !== subscriptionInfo.quantity ||
        billingPeriod != subscriptionInfo.interval) && (
        <div>
          <TermsOfUse termsUrl="#" onChange={setTermsOfUseAccepted} />
          <div className={styles.updateButton}>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!termsOfUseAccepted}
            >
              Update and pay
            </Button>
          </div>
        </div>
      )}

      <Modal
        title={"Price breakdown"}
        open={priceBreakdownIsShown}
        onClose={() => setPriceBreakdownIsShown(false)}
      >
        <div className={styles.subscriptionDetails}>
          <div className={styles.spaceBetween}>
            <p>Current plan</p>
            <p>{subscriptionInfo.interval === 'month' ? 'Monthly' : 'Annual'} billing</p>
          </div>
          <p className={styles.title}>
            <span className={styles.currentPlan}>{subscriptionInfo.name}</span>
          </p>
          <hr/>

          <div className={`${styles.spaceBetween} `}>
            <p>
              Subscription seats: {subscriptionSeats - subscriptionInfo.quantity} x {prices.find(price => price.id === subscriptionInfo.priceId)?.amount}
            </p>
            <p>
              {getCurrencySymbol(subscriptionInfo.currency)}
              {((subscriptionSeats-  subscriptionInfo.quantity) * (prices.find(price => price.id === subscriptionInfo.priceId)?.amount ?? 0)).toFixed(2)}
            </p>
          </div>
          <hr/>
          <div className={styles.spaceBetween}>
            <div>
              <p>Subtotal</p>
              <p>Reflects adjusted price for remaining time on your current plan.</p>
            </div>
            <p>{upcomingInvoice.subtotal}</p>
          </div>
          <hr/>
          <div className={styles.spaceBetween}>
            <p>VAT</p>
            <p>{upcomingInvoice.tax}</p>
          </div>
          <hr/>
          <div className={styles.spaceBetween}>
            <p>Applied balance</p>
            <p>{upcomingInvoice.startingBalance - upcomingInvoice.endingBalance}</p>
          </div>
          <div className={`${styles.spaceBetween} ${styles.planSummary}`}>
            <div>
              <p>Total due today</p>
              <p>Estimated prorated price for this change.</p>
            </div>
            <p className={styles.totalPrice}>{upcomingInvoice.amountDue}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditSubscriptionDetailsForm;
