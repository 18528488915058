import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Form, Formik } from 'formik';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { USER_ROLE } from 'features/user/userTypes';
import styles from './EditRolesForm.module.scss';

interface EditRolesFormProps {
  memberId: number;
  role: keyof typeof USER_ROLE;
  onSubmit: (memberId: number, newRole: keyof typeof USER_ROLE) => void;
}

const EditRolesForm: FC<EditRolesFormProps> = ({
  memberId,
  role,
  onSubmit,
}) => {
  const userInfo = useSelector(selectInfoUserSelector);
  const InitialValues = {
    role: { value: role || '', label: role || '' },
  };

  // Role OWNER is non-assignable: Owner must always exist, cannot change roles, and no one can become Owner
  const rolesOptions = (
    Object.keys(USER_ROLE).filter(role => role !== USER_ROLE.Owner) as Array<keyof typeof USER_ROLE>
  ).map((key) => ({
    value: key,
    label: key,
  }));

  return (
    <>
      {userInfo?.id === memberId ? (
        <p className={styles.editSelfRoleDisable}>
          You cannot edit your own role.
        </p>
      ) :
      role === USER_ROLE.Owner ? (
        <p className={styles.editSelfRoleDisable}>
          The Owner role is fixed and cannot be reassigned.
        </p>
      ) :
      (
        <div className={styles.wrapper}>
          <Formik
            enableReinitialize
            initialValues={InitialValues}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(memberId, values.role.value);
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form>
                <div className={styles.rolesWrapper}>
                  <button
                    type="button"
                    id="roleInfo"
                    className={styles.tooltip}
                    data-tooltip-content="Note: User role is set at the organization level"
                  >
                    <AiOutlineInfoCircle fontSize="14px" />
                  </button>
                  <ReactTooltip anchorId="roleInfo" />
                  <CustomFormikSelect
                    label={'User role'}
                    name="role"
                    options={rolesOptions}
                  />
                </div>

                <div className={styles.submitWrapper}>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={formik.isSubmitting}
                    preloader={formik.isSubmitting}
                  >
                    Save changes
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default EditRolesForm;
