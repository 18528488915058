import React, { ReactNode } from 'react';
import styles from './CustomCheckbox.module.scss';

interface CustomCheckboxProps {
  label: ReactNode;
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  isChecked,
  handleChange,
}) => {
  return (
    <label className={`${styles.control} ${styles.controlCheckbox}`}>
      <input
        type="checkbox"
        id={typeof label === 'string' ? label : undefined}
        checked={isChecked}
        onChange={handleChange}
      />
      <div className={styles.controlIndicator}></div>
      <span>{label}</span>
    </label>
  );
};

export default CustomCheckbox;
