import React, { FC } from 'react';
import ChapterNavigationCard from 'components/reports/ChapterNavigationCard';
import styles from './StartPage.module.scss';

const cardsData = [
  {
    title: 'Timesheets',
    description: 'Manage your work time here.',
    link: '/time-entries',
  },
  {
    title: 'Management',
    description: 'Projects and clients.',
    link: '/projects',
  },
  {
    title: 'Time-off requests',
    description: 'Tired? Have a break!',
    link: 'time-off/requests',
  },
];

const StartPage: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.navigationBlock}>
        {cardsData.map((card) => (
          <ChapterNavigationCard
            key={card.title}
            title={card.title}
            description={card.description}
            link={card.link}
          />
        ))}
      </div>
    </div>
  );
};

export default StartPage;
