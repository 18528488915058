import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CustomerInitialState } from './customerTypes';
import { getCustomerById, getCustomerByOrganizationId } from './customerActions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';

const initialState: CustomerInitialState = {
  customerInfo: {
    id: "",
    name: "",
    email: "",
    city: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    state: "",
    currency: "",
    defaultPaymentMethodId: "",
    paymentMethodType: "",
    cardBrand: "",
    cardLast4: "",
    cardExpMonth: 0,
    cardExpYear: 0,
  },
  isLoading: false,
};

export const customerSlice = createSlice({
  name: '@@subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomerById.fulfilled, (state, action) => {
      state.customerInfo = action.payload;
    });
    builder.addCase(getCustomerByOrganizationId.fulfilled, (state, action) => {
      state.customerInfo = action.payload;
    })
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const customerInfoSelector = createSelector(
  [(state: RootState) => state.customer.customerInfo],
  (info) => info
);
