import React, { FC, useRef, useState } from 'react';
import { useAppDispatch } from 'app/store';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowIcon } from 'assets/icons/ArrowIcon';
import UserIcon from 'assets/icons/UserIcon';
import {
  permissionsUserSelector,
  selectInfoUserSelector,
} from 'features/user/userSlice';
import { logout } from 'features/user/userActions';
import { useClickOutside } from 'hooks/useClickOutside';
import styles from './AccountMenu.module.scss';

const AccountMenu: FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectInfoUserSelector);
  const permissions = useSelector(permissionsUserSelector);
  const [open, setOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(popoverRef, () => setOpen(false));

  const isMyOrganizationNavLinkAvailable = permissions?.some(
    (el) => el.name === 'ViewMyOrganizationPage'
  );
  const isAccessBillingSessionsAvailable = permissions?.some(
    (el) => el.name === 'AccessBillingSessions'
  );

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <div
      ref={popoverRef}
      onClick={() => setOpen(!open)}
      className={styles.wrapper}
    >
      <div className={styles.userName}>{`${userInfo?.firstName || ''} ${
        userInfo?.lastName || ''
      }`}</div>
      <UserIcon />
      <div className={styles.menuArrow}>
        <ArrowIcon />
      </div>

      {open && (
        <div className={styles.popover}>
          <ul
            onClick={() => {
              setOpen(false);
            }}
          >
            <li>
              <Link to={'account'}>My account</Link>
            </li>
            {!!isMyOrganizationNavLinkAvailable && (
              <li>
                <Link to={'organization'}>My organization</Link>
              </li>
            )}
            {!!isAccessBillingSessionsAvailable && (
              <li>
                <Link to={'subscription'}>My subscription</Link>
              </li>
            )}
            <li>
              <button onClick={handleLogOut} aria-label={'Log out'}>
                Log out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountMenu;
